import * as Sentry from "@sentry/remix"



import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation
} from "@remix-run/react"

import { json } from "@remix-run/node"
import { useChangeLanguage } from "remix-i18next/react"
import db from "./db.server"

import * as gtag from "../app/utils/gtags.client"
import { useEffect, useState } from "react"
import i18next from "./i18next.server"
import { useTranslation } from "react-i18next"
import { authenticate } from "./shopify.server"


const getLocateFromDb = async (db, session, localLocale) => {
  const locale = await db.settings.findFirst({
    where: {
      shopId: session.shop,
    },
    select: {
      locale: true,
    },
  })

  if (locale) {
    return locale.locale
  }

  return localLocale
}

Sentry.init({
  dsn: "https://fdd3e8115dc05565b7a2e56074691f9b@o4507157415854081.ingest.de.sentry.io/4507157419196496",
  tracesSampleRate: 1.0,
})

export const loader = async ({ request }) => {
  let locale = await i18next.getLocale(request);

  try {
    const admin = await authenticate.admin(request);
    if (admin && admin.session) {
      locale = await getLocateFromDb(db, admin.session, locale);
      
    }
  } catch (error) {
    if (error?.status !== 302) {
      console.log(error)
      
      Sentry.captureException(error);
    }
  }
  

  return json({ gaTrackingId: process.env.GA_TRACKING_ID, locale });
};

export let handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "common",
}

export default function App() {
  const location = useLocation()
  const { gaTrackingId, locale } = useLoaderData()
  const [countUseEffect, setCountUseEffect] = useState(0)
  const { i18n } = useTranslation()
  useChangeLanguage(locale)

  useEffect(() => {
    if (countUseEffect === 0) {

      // Load Google Tag Manager
      (function (w, d, s, l, i) {
        w[l] = w[l] || []
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : ""
        j.async = true
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, "script", "dataLayer", "GTM-533W6ZSW");

      // Load Microsoft Clarity
      (function (c, l, a, r, i, t, y) {
        c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }
        t = l.createElement(r)
        t.async = 1
        t.src = "https://www.clarity.ms/tag/" + i
        y = l.getElementsByTagName(r)[0]
        y.parentNode.insertBefore(t, y)
      })(window, document, "clarity", "script", "m9c72p17fz")

      setCountUseEffect(1)
    }
  }, [countUseEffect]
  )

  return (
    <html lang={locale} dir={i18n.dir()}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        {process.env.NODE_ENV === "development" || !gaTrackingId ? null : (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
            />
            <script
              async
              id="gtag-init"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `,
              }}
            />
          </>
        )}
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}
